.gridSize{
    width: 50rem;
    height: 26rem;
}
.agImg{
    width: 2rem;
    height: 2rem;
}


@media(max-width:300px){
 
    .gridSize{
        overflow: visible;
    }
} 