*{
    margin: 0px;
    padding: 0px;
    text-decoration: none;

}
.container1{
    display: flex;
}
.section{
    /* width:auto; */
    height: auto;
    /* background: blue; */
    background: blue;
    display: flex;
    padding: 0px;
    margin-left: 0px;
    flex-direction: column;
}
.sect{
    /* width:auto; */
    height: 20vh;
    background: red;
    display: block;
    padding: 10px;
}
main{
    width: 150vh;
    padding: 20px;
}
.sidebar{
    background-image: linear-gradient(to bottom right, hsl(187,85%,43%), rgb(40, 46, 229) 100%);
    color: white;
    height: 100vh;
    width: 200px;
}
.top_section{
    display: flex;
    align-items: center;
    pad: 20px 15px;
}
.logo-nav{
    font: 25px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link-nav{
    display: flex;
    color: white;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link-nav:hover{
    background-color: lightskyblue;
    color: black;
    transition: all 0.5s;

}
.link-nav:active{

    background-color: lightskyblue;
color: black;
}